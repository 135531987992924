<template>
    <div class="content">
        <div id="index-view">
            <carousel-3d
                v-if="typeArr.length > 0"
                ref="swiper"
                width="500"
                height="500"
                border="0"
                :space="440"
                :inverseScaling="150"
                :perspective="10"
                :startIndex="0"
                :display="5"
                :onMainSlideClick="mainSlideClick"
            >
                <slide v-for="(item, index) in typeArr" :index="index" :key="index">
                    <div class="inner">
                        <div>{{ item.moduleName }}</div>
                        <img :src="item.img" />
                        <div class="lock-bg" v-if="item.lock">
                            <img src="../../../assets/img/index/lock.png" alt="" />
                        </div>
                    </div>
                </slide>
            </carousel-3d>
        </div>
    </div>
</template>

<script>
    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import * as Server from '@/api/server'

    export default {
        name: 'Index',
        components: {
            Carousel3d,
            Slide,
        },
        data() {
            return {
                typeArr: [],
            }
        },

        mounted() {
            let startX
            let endX
            let that = this
            let swiper = document.getElementById('index-view')

            swiper.addEventListener('touchmove', function(ev) {
                let touch = event.targetTouches[0]
                endX = touch.pageX
            })

            swiper.addEventListener('touchstart', function(ev) {
                let touch = event.targetTouches[0]
                startX = touch.pageX
            })

            swiper.addEventListener('touchend', function(ev) {
                let distanceX = endX - startX

                if (!endX) return

                if ((distanceX > 0 && distanceX < 50) || (distanceX < 0 && distanceX > -50)) return

                if (distanceX < 0) {
                    that.$refs.swiper.goNext()
                } else {
                    that.$refs.swiper.goPrev()
                }

                startX = endX = 0
            })

            this.$nextTick(() => {
                this.getLimit()
            })
        },
        methods: {
            async getLimit() {
                await Server.Axios({
                    method: 'GET',
                    url: 'f/user/limit',
                    config: {
                        data: {},
                    },
                    loading: false,
                }).then((res) => {
                    this.getTopList(res.data.returnData)
                })
            },

            async getTopList(limit) {
                let data = {
                    method: 'GET',
                    url: 'f/module/topList',
                    config: {
                        data: {},
                    },
                    loading: false,
                }
                let dataSource = await Server.Axios(data)

                let result = dataSource.data.returnData
                let tempArr = []
                result.forEach((el, index) => {
                    let img = ''
                    let lock = false
                    switch (el.moduleName) {
                        case '生态环境和资源保护领域':
                            img = require('../../../assets/img/index/img1.png')
                            break
                        case '食品药品安全领域':
                            img = require('../../../assets/img/index/img2.png')
                            lock = limit == '3'
                            break
                        case '国有财产保护领域':
                            img = require('../../../assets/img/index/img3.png')
                            lock = limit == '3'
                            break
                        case '英烈权益保护领域':
                            img = require('../../../assets/img/index/img4.png')
                            lock = limit == '3'
                            break
                        case '国有土地使用权出让领域':
                            img = require('../../../assets/img/index/img5.png')
                            lock = limit == '3'
                            break
                        default:
                            // img = require('../../../assets/img/index/img0.png')
                            // lock = limit == '3'
                            break
                    }

                    tempArr.push({
                        moduleId: el.moduleId,
                        moduleName: el.moduleName,
                        img: img,
                        hasQuestion: el.hasQuestion,
                        lock: lock,
                    })
                })
                let typeArrTemp = []
                for (let i = 0; i < tempArr.length; i++) {
                    typeArrTemp.push(tempArr[i])
                }

                console.log(typeArrTemp, 22)
                console.log(tempArr.length)
                // if (tempArr.length < 5) {
                //     for (let i = 0; i < 5 - tempArr.length; i++) {
                //         typeArrTemp.push({
                //             moduleName: '未知领域',
                //             img: require('../../../assets/img/index/img0.png'),
                //             hasQuestion: false,
                //         })
                //     }
                // }
                typeArrTemp.forEach((element) => {
                    this.typeArr.push(element)
                })
                // this.typeArr = [
                //     typeArrTemp[0],
                //     typeArrTemp[2],
                //     typeArrTemp[4],
                //     typeArrTemp[3],
                //     typeArrTemp[1],
                // ]
            },

            mainSlideClick(obj) {
                if (this.typeArr[obj.index].lock) {
                    this.$message.warning(
                        '您的服务已到期，部分功能已受限，如有问题，请联系工作人员'
                    )
                    return false
                }
                if (this.typeArr[obj.index].hasQuestion) {
                    this.$router.push({
                        name: 'Question',
                        query: {
                            moduleId: this.typeArr[obj.index].moduleId,
                            moduleName: this.typeArr[obj.index].moduleName,
                        },
                    })
                } else {
                    if (this.typeArr[obj.index].moduleId) {
                        this.$message.warning('该领域下未维护问题')
                    } else {
                        this.$message.warning('未维护领域信息')
                    }
                }
            },
        },
    }
</script>

<style lang="less" type="text/less">
    .content {
        width: 100vw;
        height: 100%;
        position: relative;
        overflow: hidden;
        #index-view {
            margin-top: 180px;
            .carousel-3d-slide {
                background-color: rgba(0, 0, 0, 0);
            }
        }
        .inner {
            position: relative;
            cursor: pointer;
            -webkit-box-reflect: below 10px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.5, transparent), to(rgba(255, 255, 255, 0.18)));
            div {
                width: 100%;
                color: #fff;
                font-size: 28px;
                position: absolute;
                bottom: 72px;
                text-align: center;
                text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3);
                cursor: pointer;
                img {
                    cursor: pointer;
                }
            }
            .lock-bg {
                width: 100%;
                height: 100%;
                bottom: 0;
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                z-index: 99999999;

                img {
                    width: 80px;
                    height: 80px;
                    float: right;
                    margin: 10px;
                }
            }
        }
    }
</style>
